import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Top from "../../assets/images/about/abouttop.jpg";
import Team from "../../assets/images/about/44.jpg";
import Team2 from "../../assets/images/about/33.jpg";
import Team3 from "../../assets/images/about/22.jpg";
import axios from "../../axiosinstens";

function Section2() {
  const [about, setAbout] = useState({ description: "" });

  const getEvents = async () => {
    try {
      const response = await axios.post("getcontents", {
        name: "about_us",
      });
      const eventsData = response.data.data;
      console.log("events", eventsData);
      setAbout(eventsData);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <>
      <div className="evn">
        <h2>
          <span>About</span> Play Pro
        </h2>
        <hr />
      </div>
      <section className="about_uss">
        <div className="aboutall">
          <div className="about_left">
            <div className="aboutimmg">
              <img src={Top} alt="About Top" />
            </div>
          </div>
          <div className="about_left">
            <div className="aboutxts">
              <div className="abouright">
                <span
                  dangerouslySetInnerHTML={{
                    __html: about.header,
                  }}
                />
                <span
                  dangerouslySetInnerHTML={{
                    __html: about.description,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="team">
        <div className="evn">
          <h2>
            <span>Our</span> Teams
          </h2>
          <hr />
        </div>
        <div className="termall">
          <div className="teamss">
            <div className="temimg">
              <img src={Team} alt="Team Member 1" />
              <h4>Where can I get some?</h4>
            </div>
          </div>
          <div className="teamss">
            <div className="temimg">
              <img src={Team2} alt="Team Member 2" />
              <h4>Where can I get some?</h4>
            </div>
          </div>
          <div className="teamss">
            <div className="temimg">
              <img src={Team3} alt="Team Member 3" />
              <h4>Where can I get some?</h4>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Section2;
