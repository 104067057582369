import React from "react";
import { Container,Row,Col } from "react-bootstrap";
import Layouts from '../../Components/Layouts/Layouts';
import { Link } from "react-router-dom";

import "../../styles/CheckoutStyle.css";


function Checkout(){
    return(
        <>
            <Layouts>
        {/* <div className="evn">
          <h2><span>About</span> Play Pro</h2>
          <hr />
        </div> */}
        <section className="checkout">
           <div className="checkoutfrm">
            <h2>Checkout</h2>
            <input type="" placeholder="Player First Name" />
            <input type="" placeholder="Player Last Name" />
            <input type="" placeholder="Player Email ID" />
           </div>
        </section>
  
        </Layouts>


        </>
    );
}
export default Checkout;