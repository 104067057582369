import { Link } from "react-router-dom";

function Section1() {
  return (
    <>
      <div className="contactdetails">
        <div className="container_row">
          <div className="conttact">
            <div className="conttactt">
              <div className="con">
                <div className="contactboxicon">
                  <i class="fa-solid fa-user"></i>
                </div>
              </div>
              <div className="con">
                <div className="contactbox">Address</div>
                <div className="contactbox">
                  <h4>
                    2 Croftcroighn Place, Glasgow, Scotland, Scotland, G33 5JH
                  </h4>
                </div>
              </div>
            </div>
            <div className="conttactt">
              <div className="con">
                <div className="contactboxicon">
                  <i class="fa-solid fa-user"></i>
                </div>
              </div>
              <div className="con">
                <div className="contactbox">Email</div>
                <div className="contactbox">
                  <h4>info@playproevents.co.uk</h4>
                </div>
              </div>
            </div>
            <div className="conttactt">
              <div className="con">
                <div className="contactboxicon">
                  <i class="fa-solid fa-user"></i>
                </div>
              </div>
              <div className="con">
                <div className="contactbox">Phone</div>
                <div className="contactbox">
                  <h4>07899 026519</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container_row">
          <div className="contactfrm">
            <div className="confrmleftt">
              <div className="formleft">
                <h2>Contact Us</h2>
                <h3>Have Any Query? Please Contact Us!</h3>
                <p>
                  {" "}
                  Please fill in the form below and one of us will get back to
                  you at the earliest.
                </p>
                <div className="confrmm">
                  <div className="conname">
                    <input type="text" placeholder="First Name" />
                  </div>
                  <div className="conname">
                    <input type="text" placeholder="Last Name" />
                  </div>
                  <div className="conname">
                    <input type="text" placeholder="Your Email" />
                  </div>
                  <div className="conname">
                    <input type="text" placeholder="Your Phone Number" />
                  </div>
                  <textarea>Message :</textarea>
                  <div className="consub">
                    <input type="submit" placeholder="Submit" />
                  </div>
                </div>
              </div>
            </div>
            <div className="confrmleftt">
              <div className="formleftt">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d470832.0670212566!2d88.26618761480043!3d22.788545191101704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f8b526303b1a1f%3A0x65b80319fd28c36!2sV1%20Technologies!5e0!3m2!1sen!2sin!4v1714469077846!5m2!1sen!2sin"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Section1;
