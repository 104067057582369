import React from 'react';
import Layouts from '../../Components/Layouts/Layouts';
import Section1 from './Section1.js';
import Section2 from './Section2.js';
// import "../../styles/HomeStyle.css";


function Gallery(){
    return(
        <>
        <Layouts>
            {/* Home Section Hero Banner   */}
           {/* <Section1 /> */}
           <Section2 />

        </Layouts>
       
        </>
    )
}
export default Gallery;