import React from "react";

import { useState, useEffect } from "react";
import LayoutsHome from "../../Components/Layouts/LayoutsHome";
//  import Layouts from '../../Components/Layouts/Layouts';
import Section1 from "./Section1";
import "../../styles/HomeStyle.css";
// import "../../styles/HeaderStyle.css";
import Section2 from "./Section2";
import Section3 from "./Section3";
function Home() {
  const newYear = new Date("Oct 9,2024").getTime();

  return (
    <>
      <LayoutsHome>
        <Section1 />
        <Section2 />
        <Section3 newYear={newYear} />
      </LayoutsHome>
    </>
  );
}
export default Home;
