import React, { useState, useEffect } from "react";
import Layouts from "../../Components/Layouts/Layouts";
import { useNavigate } from "react-router-dom";

import { Container, Row, Col, Table, Button, Form } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import axios from "../../axiosinstens";
import "../../styles/LoginStyle.css";
import { useParams } from "react-router-dom";
import CustomDropdown from "./CustomDropdown";
import { loadStripe } from "@stripe/stripe-js";

function stripHtmlTags(html) {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
}

function Eventregister() {
  const stripePromise = loadStripe("pk_test_kCLEhloJ28xps7v56iZyIX6X");
  const navigate = useNavigate();
  const [inputData, setInputData] = useState({
    f_name: "",
    l_name: "",
    email: "",
    phone: "",
    dob: "",
    password: "",
    cnf_password: "",
  });

  const [loginInputData, setLoginInputData] = useState({
    email: "",
    password: "",
  });

  const [playerInput, setPlayerInput] = useState({
    f_name: "",
    l_name: "",
    email: "",
    mobile: "",
    dob: "",
    playing_position: "",
    weight: "",
    height: "",
    info: "",
    curent_school_year: "",
    user_id: "",
  });

  const [user, setUser] = useState(null);
  const [players, setPlayers] = useState([]);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { id } = useParams();
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));

    if (storedUser) {
      setUser(storedUser);
      getPlayers(storedUser.id);
      setPlayerInput({ ...playerInput, user_id: storedUser.id });
    }
  }, []);

  const handleShowModal = () => {
    setShowModal(true);
  };
  const calculateTotalPrice = () => {
    let totalPrice = 0;
    players.forEach((player) => {
      totalPrice += player.price || 0;
    });
    return totalPrice;
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    console.log("Logging in...", loginInputData);

    axios
      .post("userlogin", loginInputData)
      .then((response) => {
        console.log("Response:", response);

        const { data } = response;
        if (data) {
          localStorage.setItem("user", JSON.stringify(data.user));
          setUser(data.user);
          const userId = data.user.id;
          getPlayers(userId);
        }
      })
      .catch((error) => {
        console.error("Error logging in:", error);
      });
  };

  const handleRegister = (e) => {
    e.preventDefault();
    console.log("Registering...", inputData);

    axios.post("register", inputData).then((response) => {
      console.log("Response:", response);
      window.location.reload();
    });
  };

  const handleAddPlayer = (e) => {
    e.preventDefault();
    console.log("playerdata", playerInput);
    axios.post("addplayer", playerInput).then((response) => {
      console.log("Response:", response);
      window.location.reload();
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value });
  };

  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLoginInputData({ ...loginInputData, [name]: value });
  };

  const handlePlayerAddChange = (e) => {
    const { name, value } = e.target;
    setPlayerInput({ ...playerInput, [name]: value });
  };

  const handlePlayerInputChange = (e) => {
    let value = e.target.value;
    if (value.length > 4) {
      value = value.slice(0, 4);
    }
    setPlayerInput({ ...playerInput, curent_school_year: value });
  };
  const handlemyorder = (e) => {
    console.log("myorder".myorderinput);
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    setUser(null);
  };

  const getPlayers = async (userId) => {
    try {
      const response = await axios.post("getmyplayers", {
        eventId: id,
        user: userId,
      });
      const playerData = response.data.players;
      console.log("players", playerData);
      setPlayers(playerData);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDateChange = (playerIndex, selectedDate) => {
    const newPlayers = [...players];
    const selectedSlot = newPlayers[playerIndex].event_slots.find(
      (slot) => slot.id === selectedDate
    );
    newPlayers[playerIndex].selectedSlotId = selectedDate;
    newPlayers[playerIndex].price = selectedSlot ? selectedSlot.price : 0;
    setPlayers(newPlayers);
  };
  const totalPriceSum = players.reduce(
    (accumulator, player) => accumulator + parseFloat(player.price || 0),
    0
  );
  const handleCheckboxChange = (index) => {
    const newSelectedPlayers = [...selectedPlayers];
    newSelectedPlayers[index] = !newSelectedPlayers[index];
    setSelectedPlayers(newSelectedPlayers);
  };
  const handlePostData = (e) => {
    const isAnyPlayerSelected = selectedPlayers.some((selected) => selected);

    if (!isAnyPlayerSelected) {
      e.preventDefault();
      alert("Please select at least one player before proceeding to checkout.");
      return;
    }
    const selectedPlayersData = players
      .filter((player, index) => selectedPlayers[index])
      .map((player) => ({
        userid: player.user_id,
        playerid: player.id,
        playerName: `${player.f_name} ${player.l_name}`,
        selectedSlot: player.event_slots.find(
          (slot) => slot.id === player.selectedSlotId
        ),
      }));
    const totalPrice = players.reduce(
      (accumulator, player) => accumulator + parseFloat(player.price || 0),
      0
    );
    const dataToSend = {
      selectedPlayersData: selectedPlayersData,
      totalPrice: totalPrice,
      eventID: id,
    };

    // Set dataToSend in local storage
    localStorage.setItem("dataToSend", JSON.stringify(dataToSend));

    e.preventDefault();
    console.log("Data to Send:", dataToSend);

    navigate(`/Payment`);
    console.log("Data to Send:", dataToSend);
    // placeOrder();
  };

  const placeOrder = (dataToSend) => {
    console.log(dataToSend);
    axios
      .post("myorder", dataToSend)
      .then((response) => {
        console.log("Response:", response);
        // window.location.reload();
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("An error occurred while posting the data");
      });
  };

  const renderTable = () => (
    <div className="table-container">
      <div className="d-flex justify-content-end mb-3">
        <Button variant="primary" onClick={handleShowModal}>
          Add Player
        </Button>
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>SELECT PLAYER</th>
            <th>NAME</th>
            <th>Year Of Birth</th>
            <th>SELECT DATE & TIME</th>
            <th>PRICE</th>
          </tr>
        </thead>
        <tbody>
          {players.map((player, index) => (
            <tr key={index}>
              <td>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="checkbox"
                    checked={selectedPlayers[index] || false}
                    onChange={() => handleCheckboxChange(index)}
                    disabled={
                      !player.event_slots || player.event_slots.length === 0
                    }
                  />
                  {selectedPlayers[index] && (
                    <span style={{ marginLeft: "8px", color: "green" }}>
                      Player Selected !
                    </span>
                  )}
                </div>
              </td>
              <td>
                {player.f_name} {player.l_name}
              </td>
              <td>{new Date(player.dob).getFullYear()}</td>
              <td>
                <CustomDropdown
                  slots={player.event_slots}
                  onDateChange={handleDateChange}
                  playerIndex={index}
                />
              </td>
              <td>£{player.price || 0}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="d-flex justify-content-end align-items-center mt-3">
        <Button variant="success" onClick={handlePostData}>
          Checkout £{totalPriceSum.toFixed(2)}
        </Button>
      </div>

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        className="custom-modal-width"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Player</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddPlayer}>
            <Row>
              <Col md={6} className="playerform">
                <Form.Group controlId="formFirstName">
                  <Form.Label>Player First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter first name"
                    name="f_name"
                    value={playerInput.f_name}
                    onChange={handlePlayerAddChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="playerform">
                <Form.Group controlId="formLastName">
                  <Form.Label>Player Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter last name"
                    name="l_name"
                    value={playerInput.l_name}
                    onChange={handlePlayerAddChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6} className="playerform">
                <Form.Group controlId="formPhone">
                  <Form.Label>Player Phone</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter phone number"
                    name="mobile"
                    value={playerInput.mobile}
                    onChange={handlePlayerAddChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="playerform">
                <Form.Group controlId="formEmail">
                  <Form.Label>Player Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    name="email"
                    value={playerInput.email}
                    onChange={handlePlayerAddChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6} className="playerform">
                <Form.Group controlId="formPosition">
                  <Form.Label>Player Position</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter player position"
                    name="playing_position"
                    value={playerInput.playing_position}
                    onChange={handlePlayerAddChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="playerform">
                <Form.Group controlId="formDOB">
                  <Form.Label>Player Date Of Birth</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Enter date of birth"
                    name="dob"
                    value={playerInput.dob}
                    onChange={handlePlayerAddChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group controlId="formSchoolYear">
                  <Form.Label>Player Current School Year</Form.Label>
                  <Form.Control
                    type="number"
                    min="1900"
                    max="2100"
                    placeholder="Enter current school year"
                    name="current_school_year"
                    value={playerInput.current_school_year}
                    onChange={handlePlayerInputChange}
                    className="form-control"
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="playerform">
                <Form.Group controlId="formWeight">
                  <Form.Label>Player Weight (Kgs)</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter weight"
                    name="weight"
                    value={playerInput.weight}
                    onChange={handlePlayerAddChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6} className="playerform">
                <Form.Group controlId="formHeight">
                  <Form.Label>Player Height (cm)</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter height"
                    name="height"
                    value={playerInput.height}
                    onChange={handlePlayerAddChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="playerform">
                <Form.Group controlId="formMedicalCondition">
                  <Form.Label>Any Medical Condition?</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter medical condition"
                    name="medical_condition"
                    value={playerInput.medical_condition}
                    onChange={handlePlayerAddChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group controlId="formInfo" className="playerform">
              <Form.Label>Additional Info</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Do you currently play for a grassroots club? If so, which club do you play for and which days are matches on?"
                name="info"
                value={playerInput.info}
                onChange={handlePlayerAddChange}
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              Add Player
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );

  return (
    <Layouts>
      {!user ? (
        <section className="login">
          <div className="log-form">
            <div className="logfrmm">
              <div className="loggfrm">
                <h2>Already have an account?</h2>
                <div className="loggfrmm">
                  <h2>Login Now</h2>
                  <form onSubmit={handleLogin}>
                    <input
                      type="email"
                      placeholder="Enter your Email"
                      name="email"
                      value={loginInputData.email}
                      onChange={handleLoginChange}
                    />
                    <input
                      type="password"
                      placeholder="Enter your Password"
                      name="password"
                      value={loginInputData.password}
                      onChange={handleLoginChange}
                    />
                    <input type="submit" value="Login" />
                  </form>
                  <h3 className="reset_password">
                    Having trouble signing in?
                    <a href="#" className="reset_link">
                      Click here to reset password.
                    </a>
                  </h3>
                </div>
              </div>
            </div>
            <div className="linehight">
              <div className="lineimg">
                <img src="assets/images/contact/line.png" alt="line" />
              </div>
            </div>
            <div className="logfrmm">
              <div className="loggfrm">
                <h2>Don't have an account?</h2>
                <h2>Register Now</h2>
                <form onSubmit={handleRegister}>
                  <input
                    type="text"
                    placeholder="First Name"
                    name="f_name"
                    value={inputData.f_name}
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    placeholder="Last Name"
                    name="l_name"
                    value={inputData.l_name}
                    onChange={handleChange}
                  />
                  <input
                    type="email"
                    placeholder="Email ID"
                    name="email"
                    value={inputData.email}
                    onChange={handleChange}
                  />
                  <input
                    type="number"
                    placeholder="Mobile Number"
                    name="phone"
                    value={inputData.phone}
                    onChange={handleChange}
                  />
                  <input
                    type="date"
                    placeholder="Date Of Birth"
                    name="dob"
                    value={inputData.dob}
                    onChange={handleChange}
                  />
                  <input
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={inputData.password}
                    onChange={handleChange}
                  />
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    name="cnf_password"
                    value={inputData.cnf_password}
                    onChange={handleChange}
                  />
                  <input type="submit" value="Register" />
                </form>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <Container>
          <Row>
            <Col>{renderTable()}</Col>
          </Row>
        </Container>
      )}
    </Layouts>
  );
}

export default Eventregister;
