import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import Banner from "../../assets/images/home/banner.jpg"
import { Link } from "react-router-dom";

function Section1() {
  return (
    <section className="hero_section">
      <Container>
        <Row>
          {/* <Col lg={7} className="md-5 md-lg-0">
                        <div className="position:relative">
                            <img src={Banner} className="img-fluid" />
                        </div>
                    </Col> */}
          <Col lg={12}>
            <div className="hero_text text-center">
              <h1 className="text-white">Play Pro Events Scotland </h1>
              {/* <p>Introducing Play Pro Football Events, your premier destination for all things football.<br />  Your home to hundreds of football events <br /> across Scotland for all ages.</p> */}
              <p>
                A dedicated team of passionate football enthusiasts and
                experienced coaches.
                <br /> Our mission is simple: to provide top-tier coaching,
                unforgettable events, <br />
                and unparalleled experiences in the world of football. <br />
                <br />
                From match events to in-depth analysis, player profiles, and
                team selections, we've got you covered. <br />
                Get ready to immerse yourself in the excitement of the game like
                never before with Play Pro Football Events.
              </p>
              <Link to="/" className="register">
                Register
              </Link>
              {/* <p className="paly">
                            <Link to="https://www.youtube.com/watch?v=4xpbyTJMSOY" target="_blank" >
                            Play Full Video
                            </Link>
                            </p> */}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
export default Section1;
