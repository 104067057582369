import React from 'react';
import Layouts from '../../Components/Layouts/Layouts';
// import Section1 from '../Home/Section1.js';
import Section1 from './Section1.js';
// import "../../styles/HomeStyle.css";
import "../../styles/ContactStyle.css";


function Contact(){
    return(
        <>
        <Layouts>
            {/* Home Section Hero Banner   */}
           {/* <Section1 /> */}
           <Section1  />
        </Layouts>
        
       
        </>
    )
}
export default Contact;