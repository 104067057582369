import React, { useState } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { FaFacebook, FaTwitter, FaWhatsapp } from "react-icons/fa";
import "../../styles/HeaderStyle.css";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Logo from "../../assets/images/header/logo.png";

function HeaderHome() {
  const [nav, setNav] = useState(false);

  //Scroll Navbar

  const changeValueOnScroll = () => {
    const scrollValue = document?.documentElement?.scrollTop;
    scrollValue > 100 ? setNav(true) : setNav(false);
  };
  window.addEventListener("scroll", changeValueOnScroll);

  return (
    <headers>
      <Navbar
        collapseOnSelect
        expand="lg"
        className={`${nav === true ? "sticky" : ""} `}
      >
        <div className="container_row">
          <Navbar.Brand as={Link} to="/">
            <Link to="/" className="logo">
              <img src={Logo} alt="Logo" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link as={Link} to="/">
                Home
              </Nav.Link>
              <Nav.Link as={Link} to="/about-us">
                About Us
              </Nav.Link>

              {/* <NavDropdown title="Services" id="collapsible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
              <Nav.Link as={Link} to="/events">
                Events
              </Nav.Link>
              <Nav.Link as={Link} to="/gallery">
                Gallery
              </Nav.Link>
              <Nav.Link as={Link} to="/contact">
                Contact Us
              </Nav.Link>

              <Nav.Link as={Link} to="/login">
                <i class="fa-solid fa-user"></i> My Account
              </Nav.Link>
              <Link to="/contact" className="head">
                <i className="fab fa-facebook" style={{ color: "#0064ff" }}></i>
              </Link>
              <Link to="/contact" className="head">
                <i className="fab fa-twitter" style={{ color: "blue" }}></i>
              </Link>
              <Link to="/contact" className="head">
                <i className="fab fa-whatsapp" style={{ color: "green" }}></i>
              </Link>
              {/* <Nav.Link as={Link} to="/#">
              <div className="cart">
              <i class="bi bi-bag-plus-fill"></i>
              <em className="roundpoint">2</em>
              </div>
            </Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </headers>
  );
}
export default HeaderHome;
