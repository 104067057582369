import React, { useEffect, useState } from "react";
import axios from "../../axiosinstens";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { Link } from "react-router-dom";
const API = "http://localhost/playpro/restapi/getevents";
function Section2() {
  const [events, setEvents] = useState([]);
  const [open, setOpen] = useState(false);

  const truncateAddress = (address, wordLimit) => {
    const words = address.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return address;
  };

  const getEvents = async (url) => {
    try {
      const response = await axios.get("getevents");
      const eventsData = response.data.events;
      console.log("events", eventsData);
      const limitedEvents = eventsData.slice(0, 3);

      const truncatedEvents = limitedEvents.map((event) => {
        return {
          ...event,
          address: truncateAddress(event.address, 14),
        };
      });

      setEvents(truncatedEvents);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getEvents();
  }, []);

  const navigate = useNavigate();

  const handleeventDetailClick = (id, e) => {
    e.preventDefault();
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser) {
      navigate(`/ludus-nominati-et/${id}`);
    } else {
      setOpen(true);
    }
  };
  const handleregisterClick = (id, e) => {
    e.preventDefault();
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser) {
      navigate(`/Eventregister/${id}`);
    } else {
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {" "}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please login to register for the event.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <section className="about_us">
        <div className="container">
          <div className="aboutpara">
            <h2>Who are we?</h2>
            <p>
              At Play Pro Football Events, we're your dedicated team of
              passionate football enthusiasts and experienced coaches. Our
              mission is simple: to provide top-tier coaching, unforgettable
              events, and unparalleled experiences in the world of football.
              Whether you're a seasoned player looking to hone your skills or a
              newcomer eager to learn the ropes, we're here to support you every
              step of the way. With a focus on excellence, integrity, and the
              joy of the game, we strive to empower individuals and teams to
              reach their full potential on and off the field. So, whether
              you're dreaming of lifting trophies or simply kicking back with
              fellow football aficionados, join us at Play Pro Football Coaching
              and Events, where the love for the beautiful game knows no bounds.
            </p>
          </div>
        </div>
      </section>
      <div className="evn">
        <h2>Our Events</h2>
        <hr />
      </div>
      <div className="events">
        {events.map((event, index) => (
          <section key={index} className="event">
            <div className="ourevent">
              <div
                className="ourevent_left"
                onClick={(e) => handleeventDetailClick(event.id, e)}
              >
                <div className="event-details">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="evnt_detl">
                          <h3 style={{ textAlign: "center" }}>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: event.dateRange,
                              }}
                            />
                          </h3>
                        </div>
                      </div>
                      <div className="col-md-7">
                        <div className="evnt_det2">
                          <p className="ev">{event.name}</p>
                          <p>{event.address}</p>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="evnt_detl">
                          <h4>
                            From: <br /> £{event.lowestPrice}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="ourevent_right"
                onClick={(e) => handleregisterClick(event.id, e)}
              >
                <div className="registers">
                  <a href="#">Register Now</a>
                </div>
              </div>
            </div>
          </section>
        ))}
      </div>
      <div className="viewall">
        <Link to="/events">View All Events</Link>
      </div>
    </>
  );
}
export default Section2;
