import React, { useEffect, useState } from "react";
import axios from "../../axiosinstens";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import "./Event.css";

const API = "http://localhost/playpro/restapi/getevents";
function Section2() {
  const [events, setEvents] = useState([]);
  const [open, setOpen] = useState(false);
  const truncateAddress = (address, wordLimit) => {
    const words = address.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return address;
  };
  const getEvents = async () => {
    try {
      const response = await axios.get("getevents");
      const eventsData = response.data.events;
      console.log("events", eventsData);
      const truncatedEvents = eventsData.map((event) => {
        return {
          ...event,
          address: truncateAddress(event.address, 14),
        };
      });

      setEvents(truncatedEvents);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getEvents();
  }, []);

  const navigate = useNavigate();

  const handleeventDetailClick = (id, e) => {
    e.preventDefault();
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser) {
      navigate(`/ludus-nominati-et/${id}`);
    } else {
      setOpen(true);
    }
  };
  const handleregisterClick = (id, e) => {
    e.preventDefault();
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser) {
      navigate(`/Eventregister/${id}`);
    } else {
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please login to register for the event.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <div className="evn">
        <h2>
          <span>Our</span> Events
        </h2>
        <hr />
      </div>

      <div className="events">
        {events.map((event, index) => (
          <section key={index} className="event">
            <div className="ourevent">
              <div
                className="ourevent_left"
                onClick={(e) => handleeventDetailClick(event.id, e)}
              >
                <div className="event-details">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="evnt_detl">
                          <h3 style={{ textAlign: "center" }}>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: event.dateRange,
                              }}
                            />
                          </h3>
                        </div>
                      </div>
                      <div className="col-md-7">
                        <div className="evnt_det2">
                          <p className="ev">{event.name}</p>
                          <p>{event.address}</p>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="evnt_detl">
                          <h4>
                            From: <br /> £{event.lowestPrice}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="ourevent_right"
                onClick={(e) => handleregisterClick(event.id, e)}
              >
                <div className="registers">
                  <a href="#">Register Now</a>
                </div>
              </div>
            </div>
          </section>
        ))}
      </div>
    </>
  );
}

export default Section2;
