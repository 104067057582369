import React from "react";
import Layouts from "../../Components/Layouts/Layouts";
// import Section1 from '../Home/Section1.js';
import Section2 from "./Section2.js";
// import "../../styles/HomeStyle.css";
import "../../styles/AboutStyle.css";

function Event() {
  return (
    <>
      <Layouts>
        {/* Home Section Hero Banner   */}
        {/* <Section1 /> */}
        <Section2 />
      </Layouts>
    </>
  );
}
export default Event;
