// ./pages/Eventregister/Payment.js
import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Stripepayment from "../../Components/Stripepayment";

// Load your publishable key
function Payment() {
  const stripePromise = loadStripe("pk_test_kCLEhloJ28xps7v56iZyIX6X");

  return (
    <Elements stripe={stripePromise}>
      <div>
        <Stripepayment />
      </div>
    </Elements>
  );
}

export default Payment; // Export Payment as the default export
