import React from 'react';
import { useState ,useEffect} from "react";
import { Link } from "react-router-dom";
import { Container,Row,Col } from "react-bootstrap";
import Countdown from "../../assets/images/home/coundown-image.jpg";
import Homeblg from "../../assets/images/home/play-blog.jpg";
import Homeblg2 from "../../assets/images/home/play-blog2.jpg";
import Homeblg3 from "../../assets/images/home/play-blog3.jpg";



const padZero = (num) => {
    return num < 10 ? `0${num}` : num;
  };
function Section3(){

    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
  
    const newYear = new Date('September 1, 2024').getTime();

    useEffect(() => {
        const timerId = setInterval(() => {
          const now = new Date().getTime();
          const distance = (newYear - now) / 1000;
    
          if (distance > 0) {
            const days = Math.floor(distance / 60 / 60 / 24);
            const hours = Math.floor(distance / 60 / 60 % 24);
            const minutes = Math.floor((distance / 60) % 60);
            const seconds = Math.floor(distance % 60);
    
            setDays(padZero(days));
            setHours(padZero(hours));
            setMinutes(padZero(minutes));
            setSeconds(padZero(seconds));
          } else {
            clearInterval(timerId);
          }
        }, 1000);
    
        return () => clearInterval(timerId);
      }, [newYear]);



    return(
        <>
        <section className="about_us">
        <div className="container_full">
        <div className="count_section" id="countleft">
            

         {/* <p>The Countdown Is On</p> */}

           <div className="timmer">
                <div className="timmer2">
                    <div className="cntdown">The Countdown Is On</div>
                </div>
                <div className="timmer2">
                    <div className="days">
                       <p className="hour">{days}</p> 
                    <p>Days</p>
                    </div>
                    <div className="days">
                    <p className="hour">{hours}</p>
                    <p>Hours</p>
                    </div>
                    <div className="days">
                        <p className="hour">{minutes}</p>
                    <p>Minutes</p>
                    </div>
                    <div className="days">
                        <p className="hour">{seconds}</p>
                    <p>Seconds</p>
                    </div>
                </div>
                <div className="timmer2">
                    <div className="homreg">
                    <Link to="/" className="">
                           Register Now
                        </Link>
                    </div>
                </div>

            </div>
            
        </div>
        <div className="count_section">

        <img src={Countdown} className="img-fluid" />
        </div>
        </div>
        </section>

        <section className="">
          <div className='homeblog'>
            <div className='homeblogg'>
              <div className="content content-overlay">
              <div className="column col-xs-6"  id="zoomIn">
                  <Link to="/become-a-referee" className="column col-xs-6" id="zoomIn">
                      <figure> <img src={Homeblg} alt='' /></figure>
                  
                  <div className='homblgtxt'>
                      <div className='homblgtxtt'>
                        <h3>Become a Referee</h3>
                        {/* <p>Ludus nominati et</p>
                        <p><span>08:00 am - 04:00pm</span></p>
                        <p>viderer , 43st Wardour Street , London UK</p>
                        <h4>10$ </h4>
                        <h4>A Ticket </h4> */}
                        {/* <div className='atag'><Link to="#" >Register</Link></div> */}
                      </div>
                    </div>
                    </Link>
                  </div>
              </div>
            </div>
            <div className='homeblogg'>
              <div className="content content-overlay">
              <div className="column col-xs-6"  id="zoomIn">
                  <Link to="/volunteer-with-us" >
                     <figure> <img src={Homeblg2} alt='' /></figure>
                  
                  <div className='homblgtxt'>
                      <div className='homblgtxtt'>
                        <h3>Volunteer with us</h3>
                        {/* <p>Ludus nominati et</p>
                        <p><span>08:00 am - 04:00pm</span></p>
                        <p>viderer , 43st Wardour Street , London UK</p>
                        <h4>10$ </h4>
                        <h4>A Ticket </h4>
                        <div className='atag'><Link to="#" >Register</Link></div> */}
                      </div>
                    </div>
                    </Link>
                  </div>
              </div>
            </div>
            <div className='homeblogg'>
            <div className="content content-overlay">
            <div className="column col-xs-6"  id="zoomIn">
                <Link to="/sponsor-an-event" className="column col-xs-6" id="zoomIn">
                    <figure> <img src="assets/images/envdetails/1.jpg" alt='' /></figure>
                
                <div className='homblgtxt'>
                      <div className='homblgtxtt'>
                      <h3>Sponsor an Event</h3>
                        {/* <h3>April 22 , 2024</h3>
                        <p>Ludus nominati et</p>
                        <p><span>08:00 am - 04:00pm</span></p>
                        <p>viderer , 43st Wardour Street , London UK</p>
                        <h4>10$ </h4>
                        <h4>A Ticket </h4>
                        <div className='atag'><Link to="#" >Register</Link></div> */}
                      </div>
                    </div>
                    </Link>
                </div>
            </div>
            </div>
            <div className='homeblogg'>
              <div className="content content-overlay">
              <div className="column col-xs-6"  id="zoomIn">
                  <Link to="/about-us" >
                    <figure> <img src="assets/images/envdetails/2.jpg" alt='' /></figure>
                    
                    <div className='homblgtxt'>
                      <div className='homblgtxtt'>
                        <h3>About Us</h3>
                        {/* <p>Ludus nominati et</p>
                        <p><span>08:00 am - 04:00pm</span></p>
                        <p>viderer , 43st Wardour Street , London UK</p>
                        <h4>10$ </h4>
                        <h4>A Ticket </h4>
                        <div className='atag'><Link to="#" >Register</Link></div> */}
                      </div>
                    </div>
                    </Link>

                  </div>
                 
              </div>
            </div>
            <div className='homeblogg'>
            <div className="content content-overlay">
            <div className="column col-xs-6"  id="zoomIn">
                <Link to="/gallery" >
                  <figure> <img src="assets/images/envdetails/3.jpg" alt='' /></figure>
               
                <div className='homblgtxt'>
                      <div className='homblgtxtt'>
                        <h3>Photo Gallery</h3>
                        {/* <p>Ludus nominati et</p>
                        <p><span>08:00 am - 04:00pm</span></p>
                        <p>viderer , 43st Wardour Street , London UK</p>
                        <h4>10$ </h4>
                        <h4>A Ticket </h4>
                        <div className='atag'><Link to="#" >Register</Link></div> */}
                      </div>
                    </div>
                    </Link>
              </div>
            </div>
            </div>
            <div className='homeblogg'>
            <div className="content content-overlay">
              <div className="column col-xs-6"  id="zoomIn">
                <Link to="/contact" >
                  <figure> <img src="assets/images/envdetails/4.jpg" alt='' /></figure>
     
                   <div className='homblgtxt'>
                      <div className='homblgtxtt'>
                        <h3>Contacts</h3>
                        {/* <p>Ludus nominati et</p>
                        <p><span>08:00 am - 04:00pm</span></p>
                        <p>viderer , 43st Wardour Street , London UK</p>
                        <h4>10$ </h4>
                        <h4>A Ticket </h4>
                        <div className='atag'><Link to="#" >Register</Link></div> */}
                      </div>
                    </div>
                    </Link>
               </div>
            </div>
            </div>
          </div>
        </section>
        
        </>
    )
}
export default Section3;