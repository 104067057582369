import React from "react";
import Layouts from '../../Components/Layouts/Layouts';
import { Container,Row,Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Top from "../../assets/images/about/abouttop.jpg";
import Team from "../../assets/images/about/44.jpg";
import Team2 from "../../assets/images/about/33.jpg";
import Team3 from "../../assets/images/about/22.jpg";

import "../../styles/BecomearefereeStyle.css";


function Sponsoranevent(){
    return(
        <>
        <Layouts>
        
      
        <div className="evn">
          <h2><span>Volunteer</span> with us</h2>
          <hr />
        </div>
        <section className="about_uss">
            <div className="aboutall">
              <div className="about_left">
                <div className="aboutimmg">
                  {/* <img src={Top} /> */}
                  <img src="assets/images/gallery/1.jpg" />
                </div>
            </div>
            <div className="about_left">
              <div className="aboutxts">
                <div className="abouright">
                  <h3>Welcome to the Volunteer family.</h3>
                 
                  <p>Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, </p>

<p>Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia,</p>

<p>Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia,</p>

{/* <p>But our mission goes beyond just organizing events. We're here to foster a community—a community built on the shared values of sportsmanship, teamwork, and dedication. Through our programs and initiatives, we strive to inspire the next generation of football stars, instilling in them the skills, confidence, and resilience needed to succeed both on and off the pitch.</p> */}

<p>Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia,</p>
                  {/* <p> Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage,</p> */}
                </div>

              </div>
            </div>
          </div>
        </section>
       
        <section className="">
        <div className="container_row">
               <div className="contactfrm">
                  <div className="confrmleftt">
                     <div className="formleft">
                        <h2>Contact Us</h2>
                        <h3>Have Any Query? Please Contact Us!</h3>
                        <p> Please fill in the form below and one of us will get back to you at the earliest.</p>
                        <div className="confrmm">
                           <div className="conname">
                              <input type="text" placeholder="First Name" />
                           </div>
                           <div className="conname">
                              <input type="text" placeholder="Last Name" />
                           </div>
                           <div className="conname">
                              <input type="text" placeholder="Your Email" />
                           </div>
                           <div className="conname">
                              <input type="text" placeholder="Your Phone Number" />
                           </div>
                              <textarea >Message :</textarea>
                              <div className="consub">
                              <input type="submit" placeholder="Submit" />
                           </div>
                         
                        </div>
                     </div>
                  </div>
                  <div className="confrmleftt">
                     <div className="formleftt">
                     <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d470832.0670212566!2d88.26618761480043!3d22.788545191101704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f8b526303b1a1f%3A0x65b80319fd28c36!2sV1%20Technologies!5e0!3m2!1sen!2sin!4v1714469077846!5m2!1sen!2sin" ></iframe>
                     </div>
                  </div>
               </div>
            </div>
        </section>

        </Layouts>


        </>
    );
}
export default Sponsoranevent;