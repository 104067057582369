import React, { useState, useEffect } from "react";

const CustomDropdown = ({ slots, onDateChange, playerIndex }) => {
  const [selectedDate, setSelectedDate] = useState("");

  const handleSelectionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedDate(selectedValue);
    onDateChange(playerIndex, selectedValue);
  };

  const clearSelection = () => {
    setSelectedDate("");
    onDateChange(playerIndex, "");
  };

  const getDisplayText = (id) => {
    if (id === "") {
      return "Select Date & Time";
    }
    const slot = slots.find((slot) => slot.id === id);
    return slot ? `${slot.date} - ${slot.start_time}` : "Select Date & Time";
  };

  return (
    <div className="dropdown-container">
      <select
        className="form-control"
        value={selectedDate}
        onChange={handleSelectionChange}
      >
        <option value="">Select a date</option>
        {slots.map((slot, index) => (
          <option key={index} value={slot.id}>
            {slot.date} - {slot.start_time}
          </option>
        ))}
      </select>
      <button className="clear-button" onClick={clearSelection}>
        &minus;
      </button>
    </div>
  );
};

export default CustomDropdown;
