import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Layouts from "../Components/Layouts/Layouts";
import "../styles/StripePayment.css";
import axios from "../axiosinstens";
import logo from "../assets/images/weaccept.png";
import swal from "sweetalert";

const stripePromise = loadStripe("pk_test_kCLEhloJ28xps7v56iZyIX6X");

const StripePayment = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentError, setPaymentError] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(null);
  const [registerData, setRegisterData] = useState(null);
  const [storedUser, setStoredUser] = useState(null);

  useEffect(() => {
    const storedData = localStorage.getItem("dataToSend");
    const storedUserJSON = localStorage.getItem("user");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setRegisterData(parsedData);
      console.log("Data retrieved from localStorage:", parsedData);
    }
    if (storedUserJSON) {
      const parsedUser = JSON.parse(storedUserJSON);
      setStoredUser(parsedUser);
      console.log("User retrieved from localStorage:", parsedUser);
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check if Stripe, elements, registerData, and storedUser are available
    if (!stripe || !elements || !registerData || !storedUser) {
      return;
    }

    // Get the card element
    const cardElement = elements.getElement(CardElement);

    // Create the payment method using Stripe.js
    stripe
      .createPaymentMethod({
        type: "card",
        card: cardElement,
      })
      .then(async function (result) {
        if (result.error) {
          // Handle errors
          console.error(result.error.message);
          // You can show an error message to the user if needed
        } else {
          const paymentMethodId = result.paymentMethod.id;

          try {
            // Send request to backend to create PaymentIntent
            const response = await fetch(
              "http://localhost/playpro/restapi/create_payment_intent",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  amount: registerData.totalPrice,
                  email: storedUser.email,
                  payment_description: "Payment for Play Pro",
                  user_name: storedUser.name,
                  paymentMethodId: paymentMethodId,
                }),
              }
            );

            // Parse the response from the backend
            const {
              client_secret,
              charge_id,
              error: intentError,
            } = await response.json();

            if (intentError) {
              // Handle PaymentIntent creation error
              console.error(intentError);
              // Show an error message to the user using swal or other method
              return;
            }

            // Confirm the PaymentIntent with Stripe
            const { error: paymentError, paymentIntent } =
              await stripe.confirmCardPayment(client_secret, {
                payment_method: {
                  card: cardElement,
                  billing_details: {
                    name: storedUser.name,
                    email: storedUser.email,
                  },
                },
              });

            if (paymentError) {
              // Handle PaymentIntent confirmation error
              console.error(paymentError.message);
              // Show an error message to the user using swal or other method
              return;
            }

            if (
              paymentIntent.status === "requires_action" &&
              paymentIntent.next_action.type === "use_stripe_sdk"
            ) {
              // Handle additional action (e.g., 3D Secure authentication) if needed
              const { error: authError } = await stripe.handleCardAction(
                paymentIntent.client_secret
              );

              if (authError) {
                // Handle authentication error
                console.error(authError.message);
                // Show an error message to the user using swal or other method
                return;
              }
            } else if (paymentIntent.status === "succeeded") {
              const crgrespons = await fetch(
                "http://localhost/playpro/restapi/getcharge_id",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    payment_intent_id: paymentIntent.id,
                  }),
                }
              );
              const { chargeidget, error: intentError } =
                await crgrespons.json();

              // Payment succeeded, proceed with further actions
              console.log(chargeidget);
              // Send the transaction data to your backend to process the order
              const dataToSend = {
                selectedPlayersData: registerData.selectedPlayersData,
                totalPrice: registerData.totalPrice,
                eventID: registerData.eventID,
                trn_id: chargeidget,
              };

              // Make a request to your backend to process the order
              const orderResponse = await axios.post("myorder", dataToSend);

              // Handle the response from the backend
              console.log("Order Response:", orderResponse.data);
              // Show a success message to the user using swal or other method
              swal(
                "Payment Success",
                "Your payment was successful!",
                "success"
              );
            }
          } catch (error) {
            // Handle other errors (e.g., network error)
            console.error("Error:", error);
            // Show an error message to the user using swal or other method
            swal(
              "Error",
              "An error occurred while processing your payment. Please try again later.",
              "error"
            );
          }
        }
      });
  };

  return (
    <Layouts>
      <div className="payy">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              {registerData && (
                <div className="invoice">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Player Name</th>
                        <th>Time</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {registerData.selectedPlayersData.map((player) => (
                        <tr key={player.playerid}>
                          <td>{player.playerName}</td>
                          <td>{player.selectedSlot.date}</td>
                          <td>£{player.selectedSlot.price}</td>
                        </tr>
                      ))}
                      <tr>
                        <td>
                          <strong>Total</strong>
                        </td>
                        <td></td>
                        <td>
                          <strong>£{registerData.totalPrice}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            <div className="col-md-6">
              <div className="payment-form">
                <img src={logo} alt="Logo" className="logo" />
                <form onSubmit={handleSubmit}>
                  <div className="card-element">
                    <CardElement />
                  </div>
                  <button type="submit" disabled={!stripe}>
                    {registerData ? `£${registerData.totalPrice} Pay` : "Pay"}
                  </button>
                </form>
                {paymentSuccess && (
                  <div className="message success">{paymentSuccess}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layouts>
  );
};

export default StripePayment;
