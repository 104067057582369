import React, { useState, useEffect } from "react";
import Layouts from "../../Components/Layouts/Layouts";
import { Container, Row, Col, Table, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import axios from "../../axiosinstens";
import "../../styles/LoginStyle.css";
import swal from "sweetalert";
import { BounceLoader } from "react-spinners";

import { FaUser, FaEnvelope, FaPhone, FaBirthdayCake } from "react-icons/fa";
function stripHtmlTags(html) {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
}

function Login() {
  const [inputData, setInputData] = useState({
    f_name: "",
    l_name: "",
    email: "",
    phone: "",
    dob: "",
    password: "",
    cnf_password: "",
  });

  const [loginInputData, setLoginInputData] = useState({
    email: "",
    password: "",
  });

  const [playerInput, setplayerInput] = useState({
    f_name: "",
    l_name: "",
    email: "",
    mobile: "",
    dob: "",
    playing_position: "",
    weight: "",
    height: "",
    info: "",
    curent_school_year: "",
    user_id: "",
  });

  const [passwordStyle, setPasswordStyle] = useState({ borderColor: "" });
  const handlePlayerInputChange = (e) => {
    let value = e.target.value;
    if (value.length > 4) {
      value = value.slice(0, 4);
    }
    setplayerInput({ ...playerInput, curent_school_year: value });
  };

  const [user, setUser] = useState(null);
  const [activeTab, setActiveTab] = useState("table");
  const [players, setPlayers] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser) {
      setUser(storedUser);
      getPlayers(storedUser.id);
      setplayerInput({ ...playerInput, user_id: storedUser.id });
      getBookings(storedUser.id);
    }
  }, []);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  if (loading) {
    return (
      <div className="loader-container">
        <BounceLoader size={60} color={"#123abc"} loading={true} />
      </div>
    );
  }
  const handleLogin = (e) => {
    e.preventDefault();
    console.log("Logging in...", loginInputData);

    axios
      .post("userlogin", loginInputData)
      .then((response) => {
        console.log("Response:", response);

        const { data } = response;
        if (data) {
          localStorage.setItem("user", JSON.stringify(data.user));
          setUser(data.user);
          const userId = data.user.id;
          getPlayers(userId);
          getBookings(userId);
        }
      })
      .catch((error) => {
        console.error("Error logging in:", error);
      });
  };
  const validatePassword = (password) => {
    const regex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  };
  const handleRegister = (e) => {
    e.preventDefault();
    console.log("Registering...", inputData);
    if (!validatePassword(inputData.password)) {
      swal(
        "Error",
        "Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character.",
        "error"
      );
      return;
    }

    if (inputData.password !== inputData.cnf_password) {
      swal("Error", "Passwords do not match.", "error");
      return;
    }
    setLoading(true);

    axios
      .post("register", inputData)
      .then((response) => {
        console.log("Registration Response:", response);

        const userData = response.data.data;
        axios
          .post("userlogin", {
            email: userData.email,
            password: inputData.password,
          })
          .then((loginResponse) => {
            console.log("Login Response:", loginResponse);

            const { data } = loginResponse;
            if (data) {
              localStorage.setItem("user", JSON.stringify(data.user));
              setUser(data.user);
              const userId = data.user.id;
              getPlayers(userId);
              getBookings(userId);
            }
            setLoading(false);
          })
          .catch((loginError) => {
            console.error("Error logging in after registration:", loginError);
            setLoading(false);
          });
      })
      .catch((error) => {
        console.error("Error registering:", error);
        setLoading(false);
        window.location.reload();
      });
  };

  const handleaddplayer = (e) => {
    e.preventDefault();
    console.log("Player data:", playerInput);

    axios
      .post("/addplayer", playerInput)
      .then((response) => {
        console.log("Response:", response.data);
        if (response.data.success) {
          swal("Success", response.data.message, "success");
          window.location.reload();
        } else {
          swal("Error", response.data.message, "error");
        }
      })
      .catch((error) => {
        console.error("Error adding player:", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          swal("Error", error.response.data.message, "error");
        } else {
          window.location.reload();
        }
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value });
  };

  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLoginInputData({ ...loginInputData, [name]: value });
  };

  const handleplyeeraddchange = (e) => {
    const { name, value } = e.target;
    setplayerInput({ ...playerInput, [name]: value });
  };

  const handleLogout = () => {
    setInputData({
      f_name: "",
      l_name: "",
      email: "",
      phone: "",
      dob: "",
      password: "",
      cnf_password: "",
    });

    setLoginInputData({
      email: "",
      password: "",
    });

    setplayerInput({
      f_name: "",
      l_name: "",
      email: "",
      mobile: "",
      dob: "",
      playing_position: "",
      weight: "",
      height: "",
      info: "",
      curent_school_year: "",
      user_id: "",
    });

    localStorage.removeItem("user");
    setUser(null);
  };

  const getPlayers = async (user) => {
    try {
      const response = await axios.post("getmyplayers", { user });
      const playerData = response.data.players;
      console.log("players", playerData);
      setPlayers(playerData);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getBookings = async (user) => {
    try {
      const response = await axios.post("getmybookings", { user });
      const bookingData = response.data.data;
      console.log("bookings", bookingData);
      setBookings(bookingData);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const renderTable = () => (
    <div className="table-container">
      <div className="d-flex justify-content-end mb-3">
        <Button variant="primary" onClick={handleShowModal}>
          Add Player
        </Button>
      </div>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>NAME</th>
            <th>DOB</th>
            <th>PHONE</th>
            <th>EMAIL</th>
            <th>PLAYER POSITION</th>
            <th>WEIGHT</th>
            <th>HEIGHT</th>
            <th>MEDICAL CONDITION</th>
            <th>INFO</th>
          </tr>
        </thead>
        <tbody>
          {players.map((player, index) => (
            <tr key={index}>
              <td>
                {player.f_name} {player.l_name}
              </td>
              <td>{player.dob}</td>
              <td>{player.mobile}</td>
              <td>{player.email}</td>
              <td>{player.playing_position}</td>
              <td>{player.weight}kg</td>
              <td>{player.height}</td>
              <td>{player.medical_condition}</td>
              <td>{stripHtmlTags(player.info)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        ClassName="custom-modal-width"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Player</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleaddplayer}>
            <Row>
              <Col md={6} className="playerform">
                <Form.Group controlId="formFirstName">
                  {/* <Form.Label>Player First Name</Form.Label> */}
                  <Form.Control
                    type="text"
                    placeholder=" Player first name"
                    name="f_name"
                    value={playerInput.f_name}
                    onChange={handleplyeeraddchange}
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="playerform">
                <Form.Group controlId="formLastName">
                  {/* <Form.Label>Player Last Name</Form.Label> */}
                  <Form.Control
                    type="text"
                    placeholder=" Player last name"
                    name="l_name"
                    value={playerInput.l_name}
                    onChange={handleplyeeraddchange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6} className="playerform">
                <Form.Group controlId="formPhone">
                  {/* <Form.Label>Player Phone</Form.Label> */}
                  <Form.Control
                    type="text"
                    placeholder="Player phone number"
                    name="mobile"
                    value={playerInput.mobile}
                    onChange={handleplyeeraddchange}
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="playerform">
                <Form.Group controlId="formEmail">
                  {/* <Form.Label>Player Email</Form.Label> */}
                  <Form.Control
                    type="email"
                    placeholder="Player email"
                    name="email"
                    value={playerInput.email}
                    onChange={handleplyeeraddchange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6} className="playerform">
                <Form.Group controlId="formPosition">
                  {/* <Form.Label>Player Position</Form.Label> */}
                  <Form.Control
                    type="text"
                    placeholder="Player position"
                    name="playing_position"
                    value={playerInput.playing_position}
                    onChange={handleplyeeraddchange}
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="playerform">
                <Form.Group controlId="formDOB">
                  {/* <Form.Label>Player Date Of Birth</Form.Label> */}
                  <Form.Control
                    type="date"
                    placeholder="Player Date of Birth"
                    name="dob"
                    value={playerInput.dob}
                    onChange={handleplyeeraddchange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group controlId="formSchoolYear">
                  {/* <Form.Label>Player Current School Year</Form.Label> */}
                  <Form.Control
                    type="number"
                    min="1900"
                    max="2100"
                    placeholder="Player current school year"
                    name="current_school_year"
                    value={playerInput.current_school_year}
                    onChange={handlePlayerInputChange}
                    className="form-control"
                  />
                </Form.Group>
              </Col>

              <Col md={6} className="playerform">
                <Form.Group controlId="formWeight">
                  {/* <Form.Label>Player Weight (Kgs)</Form.Label> */}
                  <Form.Control
                    type="number"
                    placeholder="Player weight e.g., 70 kg"
                    name="weight"
                    value={playerInput.weight}
                    onChange={handleplyeeraddchange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6} className="playerform">
                <Form.Group controlId="formHeight">
                  {/* <Form.Label>Player Height (cm)</Form.Label> */}
                  <Form.Control
                    type="text"
                    placeholder="Player height e.g., 5' 9&quot;"
                    name="height"
                    value={playerInput.height}
                    onChange={handleplyeeraddchange}
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="playerform">
                <Form.Group controlId="formMedicalCondition">
                  {/* <Form.Label>Any Medical Condition ?</Form.Label> */}
                  <Form.Control
                    type="text"
                    placeholder="Any medical conditions?"
                    name="medical_condition"
                    value={playerInput.medical_condition}
                    onChange={handleplyeeraddchange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group controlId="formInfo" className="playerform">
              {/* <Form.Label>Additional Info</Form.Label> */}
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Do you currently play for a grassroots club? If so, which club do you play for and which days are matches on?"
                name="info"
                value={playerInput.info}
                onChange={handleplyeeraddchange}
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              Add Player
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );

  const renderProfile = () => (
    <div className="profile-container bg-light p-4 rounded shadow-sm">
      {/* <div className="text-c mb-4">
        <div className="profile-picture mb-3">
          <img
            src="path/to/default/profile/picture.png"
            alt="Profile"
            className="rounded-circle shadow-sm"
          />
        </div>
        <h2 className="mb-2">Profile</h2>
      </div> */}
      <div className="profile-item mb-3 d-flex align-items-center">
        <FaUser className="profile-icon me-2" />
        <span>
          <strong>Name:</strong> {user.f_name} {user.l_name}
        </span>
      </div>
      <div className="profile-item mb-3 d-flex align-items-center">
        <FaEnvelope className="profile-icon me-2" />
        <span>
          <strong>Email:</strong> {user.email}
        </span>
      </div>
      <div className="profile-item mb-3 d-flex align-items-center">
        <FaPhone className="profile-icon me-2" />
        <span>
          <strong>Phone:</strong> {user.phone}
        </span>
      </div>
      <div className="profile-item mb-3 d-flex align-items-center">
        <FaBirthdayCake className="profile-icon me-2" />
        <span>
          <strong>Date of Birth:</strong> {user.dob}
        </span>
      </div>
      <div className="d-flex justify-content-center mt-4">
        <Button
          variant="danger"
          className="profile-logout-btn"
          onClick={handleLogout}
        >
          Logout
        </Button>
      </div>
    </div>
  );

  const renderBookings = () => (
    <div className="table-container">
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Booking ID</th>
            <th>Event Name</th>
            <th>Player Name</th>
            <th>Event Date & Time</th>
            <th>Amount</th>
            <th>View</th>
          </tr>
        </thead>
        <tbody>
          {bookings.map((booking, index) => (
            <tr key={index}>
              <td>{booking.orderId}</td>
              <td>{booking.event_name}</td>
              <td>
                {booking.player_first_name} {booking.player_last_name}
              </td>
              <td>
                {booking.slot_date} {booking.start_time}
              </td>
              <td>£{booking.amount}</td>
              <td></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );

  return (
    <>
      <Layouts>
        {!user ? (
          <section className="login">
            <div className="log-form">
              <div className="logfrmm">
                <div className="loggfrm">
                  <h2>Already have an account?</h2>
                  <div className="loggfrmm">
                    <h2>Login Now</h2>
                    <form onSubmit={handleLogin}>
                      <input
                        type="email"
                        placeholder="Enter your Email"
                        name="email"
                        value={loginInputData.email}
                        onChange={handleLoginChange}
                      />
                      <input
                        type="password"
                        placeholder="Enter your Password"
                        name="password"
                        value={loginInputData.password}
                        onChange={handleLoginChange}
                      />
                      <input type="submit" value="Login" />
                    </form>
                    <h3 className="reset_password">
                      Having trouble signing in?
                      <a href="#" className="reset_link">
                        Click here to reset password.
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="linehight">
                <div className="lineimg">
                  <img src="assets/images/contact/line.png" alt="line" />
                </div>
              </div>
              <div className="logfrmm">
                <div className="loggfrm">
                  <h2>Don't have an account?</h2>
                  <h2>Register Now</h2>
                  <form onSubmit={handleRegister}>
                    <input
                      type="text"
                      placeholder="First Name"
                      name="f_name"
                      value={inputData.f_name}
                      onChange={handleChange}
                    />
                    <input
                      type="text"
                      placeholder="Last Name"
                      name="l_name"
                      value={inputData.l_name}
                      onChange={handleChange}
                    />
                    <input
                      type="email"
                      placeholder="Email ID"
                      name="email"
                      value={inputData.email}
                      onChange={handleChange}
                    />
                    <input
                      type="number"
                      placeholder="Mobile Number"
                      name="phone"
                      value={inputData.phone}
                      onChange={handleChange}
                    />
                    <input
                      type="date"
                      placeholder="Date Of Birth"
                      name="dob"
                      value={inputData.dob}
                      onChange={handleChange}
                    />
                    <input
                      type="password"
                      placeholder="Password"
                      name="password"
                      value={inputData.password}
                      onChange={handleChange}
                    />
                    <p>
                      Password must be at least 8 characters long, contain at
                      least one uppercase letter, one lowercase letter, one
                      number, and one special character.
                    </p>
                    <input
                      type="password"
                      placeholder="Confirm Password"
                      name="cnf_password"
                      value={inputData.cnf_password}
                      onChange={handleChange}
                    />
                    <input type="submit" value="Register" />
                  </form>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <Container>
            <Row className="tabs-container">
              <Col md={4}>
                <Button
                  className={`tab-button ${
                    activeTab === "table" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("table")}
                  style={{ width: "100%" }}
                >
                  My Players
                </Button>
              </Col>
              <Col md={4}>
                <Button
                  className={`tab-button ${
                    activeTab === "profile" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("profile")}
                  style={{ width: "100%" }}
                >
                  My Profile
                </Button>
              </Col>
              <Col md={4}>
                <Button
                  className={`tab-button ${
                    activeTab === "bookings" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("bookings")}
                  style={{ width: "100%" }}
                >
                  My Bookings
                </Button>
              </Col>
            </Row>

            <Row>
              <Col>
                {activeTab === "table" && renderTable()}
                {activeTab === "profile" && renderProfile()}
                {activeTab === "bookings" && renderBookings()}
              </Col>
            </Row>
          </Container>
        )}
      </Layouts>
    </>
  );
}

export default Login;
