import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import Gallery from "./pages/Gallery/Gallery";
import About from "./pages/About/AboutUs";
import Event from "./pages/Event/Event";
import Ludusnominatiet from "./pages/Eventdetails/Ludus-nominati-et";
import Contact from "./pages/Contact/Contact";
import Login from "./pages/Login/Login";
import Checkout from "./pages/Checkouts/Checkout";
import Eventregister from "./pages/Eventregister/Eventregister";
import Becomearefree from "./pages/Blog/become-a-referee";
import Volunteerwithus from "./pages/Blog/Volunteer-with-us";
import Sponsoranevent from "./pages/Blog/Sponsor-an-event";
import Payment from "./pages/Eventregister/Payment";
function App() {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/events" element={<Event />} />
        <Route path="/ludus-nominati-et/:id" element={<Ludusnominatiet />} />
        <Route path="/Eventregister/:id" element={<Eventregister />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/login" element={<Login />} />
        <Route path="/Payment" element={<Payment />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/become-a-referee" element={<Becomearefree />} />
        <Route path="/volunteer-with-us" element={<Volunteerwithus />} />
        <Route path="/sponsor-an-event" element={<Sponsoranevent />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
