import React,{useState} from "react";
import { Container,Row,Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"


const images = [
    "assets/images/gallery/1.jpg",
    "assets/images/gallery/2.jpg",
    "assets/images/gallery/3.jpg",
    //...
    
   
    
    "assets/images/gallery/4.jpg",
    "assets/images/gallery/5.jpg",
    "assets/images/gallery/6.jpg",
  
  
    "assets/images/gallery/7.jpg",
    "assets/images/gallery/8.jpg",
    "assets/images/gallery/9.jpg",

    "assets/images/gallery/10.jpg", 
    "assets/images/gallery/11.jpg",
    "assets/images/gallery/12.jpg",


]

function Section2(){
    const [data,setData] = useState({img: '',i:0})
    const viewImage =(img,i)=>{
// console.log(img,i)
setData({img,i})
    }
    const imgAction = (action) =>{
        let i = data.i;
        if(action === 'next-img'){
            setData({img: images[i + 1], i: i+1});
        }
        if(action === 'previous-img'){
            setData({img: images[i - 1], i: i-1});
        }
        if(!action){
            setData({img: '', i: 0});
        }
    }
    return(
        <>
        {
            data.img &&
            <div style={{

                width: '100%',
                height: '90vh',
                margin:'0 auto',
                background:'black',
                position:'fixed',
                  display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',

                // width: '60%',
                // height: '100%',
                // margin:'0 auto',
                // background:'black',
                // position: 'fixed',
                // display: 'flex',
                // justifyContent: 'center',
                // alignItems: 'center',
                // overflow: 'hidden',
            }}> 
                
            <div style={{margin: '0 auto', position: 'absolute',transform: 'translate(-50%, -50%)',top: '50%',left: '50%',
    background: '#000'}}>
            <button onClick={()=>imgAction()} style={{position:'absolute',top:'5px',right:'10px', zIndex:9999, borderRadius:'50%',padding:'10px 15px'}}>X</button>
            <button onClick={()=>imgAction('previous-img')}>Previous</button>
             <img src={data.img} style={{width:"auto",maxWidth:'70%',height:'600px',objectFit:'cover'}} alt="" />
             <button onClick={()=>imgAction('next-img')}>Next</button>
            </div>
             </div>
        }
        <section className="about_us">
        <div className="gallery">
        <ResponsiveMasonry
                columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
            >
                <Masonry>
                    {images.map((image, i) => (
                        <img
                            key={i}
                            src={image}
                            style={{width: "100%", display: "block",cursor:'pointer'}}
                            alt=""
                            onClick={()=>viewImage(image,i)}
                        />
                    ))}
                </Masonry>
            </ResponsiveMasonry>
        </div>
        </section>
      


        </>
    )
}
export default Section2;