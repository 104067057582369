import Header from "./Header";
import Footer from "./Footer";
function Layouts({children}){
    return(
        <>
        <Header />
        <div>{children}</div>
        {/* <h2>I am Layouts section</h2> */}
        <Footer />
        </>
    )
}
export default Layouts;