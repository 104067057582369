import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../../axiosinstens";
import { BounceLoader } from "react-spinners";
import Layouts from "../../Components/Layouts/Layouts";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "../../styles/LudusStyle.css";

function Ludusnominatiet() {
  const { id } = useParams();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const response = await axios.post("eventdetails", {
          eventId: id,
        });
        console.log("eventdetails", response.data.data);
        setEvent(response.data.data);
      } catch (error) {
        console.error("Error fetching event details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEventDetails();
  }, [id]);
  const navigate = useNavigate();
  if (loading) {
    return (
      <div className="loader-container">
        <BounceLoader size={60} color={"#123abc"} loading={true} />
      </div>
    );
  }
  const handleRegisterClick = (e) => {
    e.preventDefault();
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser) {
      navigate(`/Eventregister/${id}`);
    } else {
      setOpen(true);
    }
  };
  if (!event) {
    return <div>No event details found</div>;
  }

  return (
    <>
      <Layouts>
        <section className="eventdetail">
          <div className="aboutall">
            <div className="about_left">
              <div className="eventdetailsimg">
                <img src={event.image_url} alt="Event" />
              </div>
            </div>
            <div className="about_left">
              <div className="aboutxts">
                <div className="eventdetails">
                  <p>
                    <span>
                      <strong>{event.shortest_date_formatted}</strong>
                    </span>{" "}
                    | <span>{event.address}</span>
                  </p>
                  <h3>{event.name}</h3>

                  <p>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: event.description,
                      }}
                    />
                  </p>
                  <div className="eventbtnn">
                    <button onClick={handleRegisterClick}>Register</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="aboutall">
            <div className="eventtime">
              <h3>Time & Location</h3>
              <div className="timedat">
                <span>2 more dates</span>
              </div>
              <div className="timedat">
                <span>{event.time}</span>
              </div>
              <div className="timebbt">
                <Link to="#">Select Different Date</Link>
              </div>
            </div>
          </div> */}
          <div className="aboutalls">
            <div className="eventtime">
              <h3>About the event</h3>
              <p>
                <span
                  dangerouslySetInnerHTML={{
                    __html: event.description,
                  }}
                />
              </p>
            </div>
          </div>
          <div className="ticketde">
            <div className="eventtime">
              <h3>Tickets</h3>
              <div className="ticketdetailss">
                <div className="tickdivid">
                  <span>Ticket type</span>
                  <p>PLAYER PASS</p>
                </div>
                <div className="tickdivid">
                  <span>Price</span>
                  <p>{event.price}</p>
                </div>
                <div className="tickdivid">
                  <span>Number of Players</span>
                  <p>
                    <input type="text" placeholder="" />
                  </p>
                </div>
              </div>
              {/* <div className="checkout">
                <div className="check">
                  <div className="totall">
                    <span>Total</span>
                    <span>{event.price}</span>
                  </div>
                  <div className="che">
                    <button onClick={handleRegisterClick}>Register</button>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
      </Layouts>
    </>
  );
}

export default Ludusnominatiet;
