import React from "react";
import { Link } from "react-router-dom";
import "../../styles/FooterStyle.css";
import Logo from "../../assets/images/footer/white-logo.png";

function Footer() {
  return (
    <section className="footer">
      <div className="container_row">
        <div className="row_footer">
          <div className="footercol1">
            <div className="footerlogo">
              <Link to="/" className="logo">
                <img src={Logo} alt="Logo" />
              </Link>
            </div>
          </div>
          <div className="footercol2">
            <div className="footline">
              <div className="footersec">
                <Link to="/" className="">
                  Contact Us
                </Link>
              </div>
              <div className="footersec">
                <Link to="/" className="">
                  Become a Referee
                </Link>
              </div>
              <div className="footersec">
                <Link to="/" className="">
                  Volunteer with us
                </Link>
              </div>
              <div className="footersec">
                <Link to="/" className="">
                  Sponsor an Event
                </Link>
              </div>
            </div>
          </div>
          <div className="footercol2">
            <div className="footline">
              <div className="footersec">
                <Link to="/" className="">
                  Home
                </Link>
              </div>
              <div className="footersec">
                <Link to="/" className="">
                  About
                </Link>
              </div>
              <div className="footersec">
                <Link to="/" className="">
                  Events
                </Link>
              </div>
              <div className="footersec">
                <Link to="/" className="">
                  Gallery
                </Link>
              </div>
            </div>
          </div>
          <div className="footercol3">
            <div className="footlines">
              <div className="footersec">
                <Link to="/" className="">
                  Follow us on
                </Link>
              </div>
              <div className="footersec">
                <Link to="/" className="">
                  -Twitter
                </Link>
              </div>
              <div className="footersec">
                <Link to="/" className="">
                  -Facebook
                </Link>
              </div>
              <div className="footersec">
                <Link to="/" className="">
                  -YouTube
                </Link>
              </div>
              <div className="footersec">
                <Link to="/" className="">
                  -Instagram
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row_footer2">
          <div className="privacy">
            <Link to="/" className="">
              Privacy
            </Link>
          </div>
          <div className="privacy">
            <Link to="/" className="">
              Terms and conditions
            </Link>
          </div>
          <div className="privacy">
            <Link to="/" className="">
              Cookie policy
            </Link>
          </div>
          <div className="privacys">
            <Link to="/" className="">
              Cookie Settings
            </Link>
          </div>
        </div>
        <div className="row_footer3">
          <div className="privac">
            <p>
              Play Pro Scouting Event, the Play Pro logo and all marks related
              to Play Pro competitions, are protected by trademarks and/or
              copyright of PLAY PRO EVENTS LTD, Company Registration Number:
              SC807588. No use for commercial purposes may be made of such
              trademarks. Use of our website signifies your agreement to the
              Terms and Conditions and Privacy Policy.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
